<template>
    <header>
        <div class="header">

            <div class="header-top d-padding">
                <div class="container-fluid">

                    <div class="header-top__content">
                        <div class="language-switch" v-if="$isDesktopScreen"></div>
                        <div class="feedback-search">
                            <button v-if="$isDesktopScreen" @click="showFeedbackModal" class="btn mr20 feedback-btn">
                                الملاحظات
                            </button>
                            <keyword-search v-if="$isDesktopScreen" :initialValue="searchWord" @searchWordChanged="searchWordChanged"></keyword-search>
                        </div>
                    </div>
                </div>
            </div>

            <div class="header-bottom" v-if="!$isMobileScreen">
                <div class="container-fluid">
                    <div class="navbar">

                        <button class="no-btn brand p-0" @click="goHome">
                            <h1 class="logo do-not-translate" data-wg-notranslate="">
                                <img :src="currentLogo" alt="Das Logo für die Kiel.RefugeeCompass-App">
                            </h1>
                        </button>

                        <desktop-nav></desktop-nav>

                    </div>
                </div>
            </div>

            <div :class="['header-bottom header-bottom-mobile', sticky ? 'sticky' : '']" v-show="(isHome || showHeader) && $isMobileScreen">
                <div class="container-fluid">
                    <button @click="activateSearch" class="btn-round float-right search-btn" aria-label="Suche">
                        <span class="material-icons search-icon" aria-hidden="true" data-wg-notranslate="">search</span>
                    </button>
                    <button @click="showFeedbackModal" aria-label="Feedback übermitteln" class="btn float-right">الملاحظات
                    </button>
                    <div class="language-switch"></div>
                </div>
            </div>
            <keyword-search v-if="!$isDesktopScreen && search" :initialValue="searchWord" @searchWordChanged="searchWordChanged"></keyword-search>
            <div v-show="(isHome || showHeader) && $isMobileScreen" class="compass-logo">
                <router-link :to="{ name: 'Home'}"><img src="/assets/logo/RefugeeCompass_Logo12.png"></router-link></div>
            </div>
            <div v-if="$isMobileScreen" class="mobile">
                <mobile-nav></mobile-nav>
            </div>

        </header>
    </template>

    <script>
        import { bus } from '@/main';
        import { screenSizeMixin } from '@/mixins/screenSizeMixin';

        export default {
          name: 'Header',
          components: {
           KeywordSearch: () => import('@/components/controls/KeywordSearch'),
           LanguageSwitcher: () => import('@/components/controls/LanguageSwitcher'),
           DesktopNav: () => import('./components/DesktopNav'),
           MobileNav: () => import('./components/MobileNav'),
       },
       mixins: [screenSizeMixin],
       data() {
        return {
            lhkLogo: '/assets/logo/kiel_logo.png',
            kkkLogo: '/assets/logo/RefugeeCompass_Logo55h.png',
            currentLogo: '/assets/logo/kiel_logo.png',
            search: false,
            translate: true,
            searchWord: '',
            sticky: false,
        }
    },
    watch: {
        currentPathName: function () {
            if (this.currentPathName!="Home"){
                this.currentLogo = this.kkkLogo;
            }
            else{
                this.currentLogo = this.lhkLogo;
            }

            if(this.currentPathName=="Mehr"){
                this.search = false;
                this.translate = false;
            }
            else{
                this.translate = true;
            }
        }
    },
    mounted () {
        this.sticky = window.scrollY >= 42;
        window.addEventListener('scroll', this.scrollHandler);
    },
    beforeDestroyed () {
        window.removeEventListener('scroll', this.scrollHandler)
    },
    computed: {
        currentPathName(){
            return this.$route.name;
        },
        isHome() {
          return this.$route.path === '/' ? true : false;
      },
      showHeader() {
          return this.$route.meta.showHeader === true ? true : false;
      },
  },
  methods: {
    activateSearch(){
      this.search = !this.search;
  },
  goHome(){
    if(this.isHome){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else{
        this.$router.push('/')
    }
},
searchWordChanged(word){
    this.$router.push({ name: 'Search', query: { keyword: word } });
    this.searchWord = "";
},
scrollHandler () {
  this.sticky = window.scrollY >= 42;
      /*is only used if the current path is home - we need to show the default lhk kiel Logo*/
  if (this.currentPathName=="Home"){
    if (window.scrollY > 100) {
        return (this.currentLogo = this.kkkLogo);
    }
    if (window.scrollY <= 100) {
        if (this.currentLogo != this.lhkLogo) {
            return (this.currentLogo = this.lhkLogo);
        }
    }
}
},
showFeedbackModal() {
    bus.$emit('show-feedback-modal', true);
},
toogleLayoutDirection(){
    bus.$emit('toggle-layout-direction');
},
}
}
</script>
<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';

    .header-bottom-mobile {
        background: #E8F6FC;
    }

    button {
        text-transform:none;

        &.brand{
            margin-right: auto;
        }
    }

    .logo{

        img{
            margin-right: 10px;

            @media (max-width: 370px) {
                height: 35px;
                margin-right: 5px;
            }
        }
    }

    .feedback-search {
        display: flex;
        margin-left: auto;
    }

    .search {
        width: 90vw;
        position: absolute;
        top: $title-bar-size;
        height: auto;
        padding: 15px 0;
        background: $white-color;
        z-index: inherit;
        box-shadow: 0px 4px 4px 0px #00000040;


        &-btn {
            background: #FFFFFF;
            box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
            border: none;
            margin-left: 10px;
            height: 40px;
            width: 40px;

        }

        &-icon {
            position: relative;
            color: $dark-color;
            top: 2px;
        }
    }

    .feedback-btn:hover {
        color: #FFF;
        background-color: #2e55a5;
    }

    .compass-logo {
        display: flex;
        justify-content: center;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

        img {
            width: 150px;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }

    body[dir="rtl"] {
        .logo img{
           margin-right: 0px;
           margin-left: 10px; 
       }

       button {
        &.brand{
            margin-left: auto;
            margin-right: 0;
        }
    }
    .search-btn {
        margin-left: 0;
        margin-right: 10px;
    }

}

.language-switch {
    @media(max-width:991px){
        max-width: 120px;
        padding-right: 10px;
    }
}

</style>